import React, { useEffect, useState } from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import ProjectList from './ProjectList';
import { GET_MY_PROJECTS, GET_ALL_PROJECTS } from '../queries';

const ProjectListContainer = ({ history, match, showAllProjects = false }) => {
  const [searchQuery, setQuery] = useState(null);
  const [filter, setFilter] = useState({
    archived: false,
  });
  const [sort, setSort] = useState({});

  useEffect(() => {
    setFilter({});
    setSort({});
    setQuery(null);
  }, [history.location.pathname]);

  if (showAllProjects) {
    return (
      <Query
        query={GET_ALL_PROJECTS}
        variables={{ offset: 0, limit: 15, search: searchQuery, ...filter, ...sort }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, fetchMore }) => {
          if (error) throw new Error(error);

          const projects = get(data, 'projectManagement.allProjects');

          return (
            <ProjectList
              showFilters
              showSearch
              title="All Projects"
              history={history}
              projects={projects}
              loading={loading}
              onSearch={query => setQuery(query)}
              onClearSearch={() => setQuery(null)}
              onFilter={newFilter => setFilter({ ...filter, ...newFilter })}
              onSort={newSort => setSort({ ...sort, ...newSort })}
              filter={filter}
              sort={sort}
              searchQuery={searchQuery}
              onMore={() => {
                fetchMore({
                  variables: {
                    offset: projects.length,
                    limit: 5,
                  },

                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    const prevProjects = get(prev, 'projectManagement.allProjects');
                    const moreProjects = get(fetchMoreResult, 'projectManagement.allProjects');
                    const newData = {
                      ...prev,
                      projectManagement: {
                        ...prev.projectManagement,
                        allProjects: [...prevProjects, ...moreProjects],
                      },
                    };
                    return newData;
                  },
                });
              }}
            />
          );
        }}
      </Query>
    );
  }

  return (
    <Query query={GET_MY_PROJECTS}>
      {({ loading, error, data }) => {
        if (error) throw new Error(error);

        const projects = get(data, 'projectManagement.myProjects');

        return (
          <ProjectList
            title="My Projects"
            history={history}
            projects={projects}
            loading={loading}
          />
        );
      }}
    </Query>
  );
};

export default withRouter(ProjectListContainer);
