import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import ProjectsReportGrid from './ProjectsReportGrid';

const GET_ALL_PROJECTS = gql`
  fragment project on Project {
    _id
    projectCode
    name
    archived
    budget
    budgetUsed
    client {
      _id
      name
    }
  }
  query getAllProjects(
    $offset: Int
    $limit: Int
    $search: String
    $budgetUsed: Float
    $sortBy: String
    $sortOrder: Int
    $client: String
  ) {
    projectManagement {
      allProjects(
        offset: $offset
        limit: $limit
        search: $search
        budgetUsed: $budgetUsed
        sortBy: $sortBy
        sortOrder: $sortOrder
        client: $client
      ) {
        ...project
      }
    }
  }
`;

let exportFunc;

const handleExportClick = () => exportFunc();

const onExportReady = ({ getExportFunc }) => {
  exportFunc = getExportFunc();
};

const constructGridData = data => {
  return data?.projectManagement?.allProjects || [];
};

const ProjectsReport = () => {
  const { loading, data } = useQuery(GET_ALL_PROJECTS, {
    variables: {
      limit: -1
    }
  });
  const gridData = constructGridData(data);

  return (
    <Card
      loading={loading}
      border
      title="Projects"
      floating
      padded={false}
      actionComponent={
        <div className="flex">
          <div className="pl-2">
            <Button onClick={handleExportClick}>Export</Button>
          </div>
        </div>
      }
    >
      <ProjectsReportGrid onExportReady={onExportReady} rowData={gridData} />
    </Card>
  );
};

export default ProjectsReport;
