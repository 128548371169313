import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

const Wrapper = styled.div`
  height: 700px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

class PayrollAllocationReportGrid extends React.Component {
  gridApi = undefined;

  onGridReady = params => {
    this.gridApi = params.api;
    const { onExportReady } = this.props;
    onExportReady({ getExportFunc: () => this.export });
  };

  export = () => {
    const params = {
      fileName: `Projects-ExportedOn(${moment(
        new Date(),
      ).format('YYYYMMDD-hhmm')}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };

  render() {
    const { rowData = [] } = this.props;
    return (
      <Wrapper className="ag-theme-balham">
        <AgGridReact
          modules={AllCommunityModules}
          onGridReady={this.onGridReady}
          enableCellTextSelection
          rowData={rowData}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
        >
          <AgGridColumn field="projectCode" />
          <AgGridColumn field="name" />
          <AgGridColumn
              field="budget"
              valueFormatter={({ colDef, data }) => {
                const budget = data[colDef.field];
                if (budget) {
                  return numeral(budget).format('$0,0.[00]');
                }
              }}
            />
          <AgGridColumn
              field="budgetUsed"
              headerName="Budget Used %"
              valueFormatter={({ colDef, data }) => {
                const budgetUsed = data[colDef.field];
                if (budgetUsed) {
                  return numeral(budgetUsed).format('%0,0.00');
                }
              }}
            />
          <AgGridColumn field="client.name" />
          <AgGridColumn field="archived" />
        </AgGridReact>
      </Wrapper>
    );
  }
}

export default withRouter(PayrollAllocationReportGrid);
