import gql from 'graphql-tag';

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectId: String!
    $name: String!
    $description: String
    $projectCode: String
    $accountingCode: String
    $accountingCodeSuffix: String
    $sectors: [String]
    $budget: Float
    $proposedBudget: Float
    $jobName: String
    $clientId: String
    $globallyVisible: Boolean
    $complete: Boolean
    $status: String
  ) {
    updateProject(
      projectId: $projectId
      name: $name
      description: $description
      projectCode: $projectCode
      accountingCode: $accountingCode
      accountingCodeSuffix: $accountingCodeSuffix
      sectors: $sectors
      budget: $budget
      proposedBudget: $proposedBudget
      jobName: $jobName
      clientId: $clientId
      globallyVisible: $globallyVisible
      complete: $complete 
      status: $status
    )
  }
`;

export const UPDATE_PROJECT_TEMPLATE = gql`
  mutation UpdateProject(
    $projectId: String!
    $name: String!
    $description: String!
  ) {
    updateProject(
      projectId: $projectId
      name: $name
      description: $description
    )
  }
`;

export const CREATE_ENDCLIENT = gql`
  mutation createEndClient(
    $projectId: String!
    $clientName: String!
    $clientShortName: String!
    $name: String!
    $description: String!
  ) {
    addEndClient(
      projectId: $projectId
      clientName: $clientName
      clientShortName: $clientShortName
      name: $name
      description: $description
    )
  }
`;
